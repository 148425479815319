body{
font-size: 150%;
}

.swal2-title{
    color: #FF0266 !important;
    font-size: 150% !important;
}



body {
  background-color: #FFDE03;
  font-style: roboto;
  color: #FF0266;
  height: 500px;

}

.welcomecontainer {
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  text-align: center;
  color: #FF0266;
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.w3 {
  font-size: 3rem;
}

.w1 {
  font-size: 100px;
  margin-top: -9px;
}

.w2{
  font-size: 25px;
  margin-top: -49px;
  margin-bottom: -18px;
}

.w4{
  margin-top: 0;
  font-size: 18px;
  margin-bottom: -36px;
}

.w6 {
  font-size: 27px;
  margin-top: -42px;
  color: black;
}

.w5 {
  font-size: 27px;
  margin-top: -21px;
  color: black;
}

.containerwelcome {
  height: 90vh;
}

.containerbuttonstart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

body {
  background-color: #ffde03;
  font-style: roboto;
}

.navbar {
  text-align: center;
  margin: 0;
  height: 60px;
  border: none;
}

.navbar-brand {
  height: 100%;
  line-height: 140%;
  font-size: 16px;
}

.logo {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1); 
}

.logoh1 {
  margin: 0;
  font-size: 120%;
  font-weight: bolder;
}

.logoh6 {
  margin-top: 0px;
  text-transform: uppercase;
  font-weight: 300;
}

.newgame {
  align-items: center;
  font-size: 15px;
  padding-top: 19px;
  cursor: pointer;
  background-color: #FF0266;
  color: white;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1); 
}
.newgame:hover{
  background-color: black;
  color: white;
}

.restart{
  font-size: 15px;
  padding-top: 19px;
  padding-right: 20px;
  color: #FF0266;
  float: right;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1); 
}

.restart:hover{
  color: black;
}

@media (max-width: 576px){
  .newgame, .restart {
    font-size: 70%;
  }
  .navbar-brand{
    line-height: 180%;
    padding: 19px 8px 15px 8px;
  }
  .logo{
    padding-top: 15px;
  }
}

.logo a {
  text-decoration: none;
  color: inherit;
}

.newgame a {
  text-decoration: none;
  color: white;
}

.newgame a:hover {
  text-decoration: none;
  color: white;
}

.logo a:hover{
  text-decoration: none;
  color: black;
}


.loadingcontainer{
  height: 75vh;
 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px;
}
.lds-heart div {
  top: 23px;
  left: 19px;
  position: absolute;
  width: 26px;
  height: 26px;
  background: #FF0266;
  -webkit-animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  background: #FF0266;
}
.lds-heart div:before {
  left: -17px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -17px;
  border-radius: 50% 50% 0 0;
}
@-webkit-keyframes lds-heart {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  5% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  39% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@keyframes lds-heart {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  5% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  39% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}

.loadingtext {
  font-weight: bold;
  font-size: 1em;
}

.tryagain{
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-poop {
  color: brown;
  font-size: 5em;
  text-shadow: 3px 3px red;
}

.tryagain .buttontip {
  color: white;
  justify-content: center;
  border: none;
  padding: 15px 15px;
  margin-top: 20px;
  width: 200px;
  border-radius: 8px;
  background-color: #FF0266;
  font-weight: bold;
  display: flex;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2), 0 2px 8px 0 rgba(0,0,0,0.19);

}

.tryagain .buttontip:hover {
  background-color: white;
  color: #FF0266;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
}

.tip {
  font-size: 2rem;
}

.skiptext {
  margin-top: 20px;
  cursor: pointer;
  color: black;
}

.tryagain h4{
  color: black;
  text-shadow: none;
  font-size: 3rem;
  font-weight: bolder;
  text-align: center;
}
.container-heart {
  justify-content: flex-end;
  display: flex;
  margin-bottom: 7px;
  background-color: #FFDE03;
  display: block inline-block;
  cursor: none;
  width: 25%;
}

.container-heart .svg-inline--fa{
  -webkit-filter: drop-shadow(1px 1px 1px black);
          filter: drop-shadow(1px 1px 1px black);
}


span {
  font-size: 2.5em;
  display: block;
  margin-left: 4%;
}

@media (max-width: 550px) {
  span {
    margin-left: 3%;
    display: block;
    font-size: 2em;
  }
}

.hiddingheart{
  color: #FFDE03;
  }


.youareawesome{
  height: 80vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.icon-poop {
  font-size: 5em;
  color: white;
}

.youareawesome h4{
  color: black;
  text-shadow: none;
  font-size: 3rem;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.points {
    font-style: roboto;
    font-size: 3rem;
    font-weight: bolder;
    color: black;
    background-color: #FFDE03;
    cursor: none;
    display: flex;
    justify-content: center;
    align-items: center;

}

@media (max-width: 500px){
    .points{
        font-size: 2.5rem;
    }
}

.spanpoints{
    font-size: 50%;
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: 500;
}

.wrapperheartpoints{
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
}


@media (max-width: 500px){
  .wrapperheartpoints{
    font-size: 13px;
  }
}

.whole-container {
  height: calc(100vh - 60px);
  margin-top: 5px;
  padding: 8px 20px;
}

@media (max-width: 600px) {
  .whole-container {
  margin-top: 10px;
  }
}

.whole-container h1 {
  font-weight: bolder;
  height: 60px;
  font-size: 5rem;
  text-align: center;
  color: black;
}

.whole-container .vowelspink{
  font-size: inherit;
  margin-left: 1px;
}

@media (max-width: 600px) {
  .whole-container h1 {
    height: 45px;
    font-size: 4rem;
  }
}

.container-verbs{
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}


@media (min-width: 1100px) {
  .container-verbs {
    height: 400px;
  }
}

.container-verbs h1{
  align-items: center;
}

@media (max-width: 500px) {
  .container-verbs h1 {
font-size: 3.5rem;
  }
}

 /* Styling from CircularProgessbar */
.wrapper_circularprogressbar{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.wrapper_circularprogressbar span{
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-right: 20px;
  color: black;
  font-weight: 500;
  margin-bottom: 3px;
}

.CircularProgressbar{
  position: relative;
  float: right;
  width: 5rem !important;
  right: 15px;
}

@media (max-width: 500px) {
  .CircularProgressbar {
    width: 4rem !important;
  }
}


.verbsfilling {
  margin-left: 5%;
  margin-right: 5%;
  height: 500px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .verbsfilling {
    margin-top: 40px;
  }
}

.verbsfilling h1 {
  font-weight: bolder;
  height: 60px;
  font-size: 5rem;
  text-align: center;
  color: black;
}

@media (max-width: 600px) {
  .verbs h1 {
    height: 60px;
    font-size: 5rem;
  }
}

input {
  width: 70px;
  color: black;
  border-bottom: solid 6px #ff0266;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0;
  color: #ff0266;
  background-color: transparent;
  padding: 0;
  text-align: center;
}

/*styling button*/

.containerbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 10vh;

}


@media (max-width: 1100px) {
  .containerbutton {
    height: 18vh;
    min-height: 150px;
  }
}

@media (max-width: 576px) {
  .containerbutton {
    height: 7vh;
    min-height: 120px;

  }
}

.btn {
  text-transform: uppercase;
  padding: 18px 40px;
  background-color: #FF0266;
  color: white;
  font-weight: 600;
  border: none;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.btn:hover {
  color: #FF0266;
  background-color: white;
}

.nonactive {
  display: none;
}

.active {
  display: inline-block;
    margin-top: 22px;

}

input[type=text]:focus {
  border: 5px solid #FF0266;
  outline-color: none;
  transition: 0.5s;

}

textarea:focus, input:focus{
  outline: none;
}

.containerimage {
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    height: 70vh;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25);
}

.containerimage:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.box2 {
  border: transparent solid 2px;
    width: 100%;
    text-align: center;
    background: white;
    position: relative;
    top: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25);
}

.box2 h6 {
  background: white;
  font-size: 16px;
    font-weight: 900;
}

.box2 h2 {
    color: black;
    font-size: 1.6em;
    text-align: center;
  background: #FFDE03;
  margin: 0;
    /*text-shadow: 2px 2px 4px #000000;*/
  }

.box2 .group h5 {
  background: #FFDE03;
  font-size: 14px;
}

.containerbuttonimg{
  margin-top: 75px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerbuttonimg .btn {
  padding: 15px 70px;

}
  .group h5 {
  color: black;
  font-size: 1.6em;
  text-align: center;
  }

  .vowelspink{
      color: #FF0266;
      display: initial;
      font-size: 14px;
      margin-left: 0;

  }

 @media (max-width: 600px) {
     .group h5 {
     font-size: 1.2em;
     text-align: center;
   }
 }

.congrats {
    height: 630px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(#FFDE03 30%, deeppink );
}

.displaypoints{
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 14px;
    color: white;
    text-shadow: 0.3px 0.3px 0.3px white;
}

.congrats h1 {
    font-size: 100px;
}

.congrats h2 {
    text-align: center;
    font-size: 200%;
    color: white;
    font-weight: 900;
    /*text-shadow: 0.3px 0.3px 3.3px white;*/
    text-transform: uppercase;
}

.congrats a {
font-size: 75%;
    width: 250px;
}


.whatsappshare {
    justify-content: center;
    display: flex;
    flex-direction: column-reverse;
    font-size: 80%;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: white;
    margin-top: 40px;
    background-color: #25D366;
    margin-bottom: 30px;
}

.facebookshare{
    background-color: #29487D;
    margin-bottom: 30px;
    font-size: 80%;
    justify-content: center;
    display: flex;
    color: white;
}

.twittershare{
    background-color: #55ACEE;
    font-size: 80%;
    justify-content: center;
    display: flex;
    color: white;
}
#progressbar{
    background-color: white;
    width: 100%;
}

#bar {
    height: 6px;
    background-color: #FF0266;
    transition-duration: 3s;

}



.notfound {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 550px;
    color: black;
    font-size: 150%;
}

.notfound span {
    font-weight: bolder;
}
.containermenu{
    height: 400px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    overflow: auto;
}

.containermenu h4 {
    font-size: 40px;
    margin: 1px 0;
    color: slategray;
    text-align: center;
    opacity: 0.4;
}

.containermenu .h4bleiben {
    color:#FF0266;
    /* text-shadow: 2px 2px 3px rgba(255,255,255,0.3); */
    opacity: 1;
}

.containermenu .spanh4 {
    font-weight: 300;
    font-size: 20px;
    display: inline-block;
}

.menu-list-verbs {
    height: 100%;
    width: 100%;
}


.containerbuttonmenu{
    margin-top: 40px;
    width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

 div::-webkit-scrollbar {
    width: 10px;
  }
  
   /* Track  */
 div::-webkit-scrollbar-track {
    background:transparent;
    
  }
  
   /* Handle  */
  div::-webkit-scrollbar-thumb {
    background: white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.1);
    border-radius: 13px;
  } 
