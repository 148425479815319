.containerimage {
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    height: 70vh;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25);
}

.containerimage:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.box2 {
  border: transparent solid 2px;
    width: 100%;
    text-align: center;
    background: white;
    position: relative;
    top: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25);
}

.box2 h6 {
  background: white;
  font-size: 16px;
    font-weight: 900;
}

.box2 h2 {
    color: black;
    font-size: 1.6em;
    text-align: center;
  background: #FFDE03;
  margin: 0;
    /*text-shadow: 2px 2px 4px #000000;*/
  }

.box2 .group h5 {
  background: #FFDE03;
  font-size: 14px;
}

.containerbuttonimg{
  margin-top: 75px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerbuttonimg .btn {
  padding: 15px 70px;

}