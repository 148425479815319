.containermenu{
    height: 400px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    overflow: auto;
}

.containermenu h4 {
    font-size: 40px;
    margin: 1px 0;
    color: slategray;
    text-align: center;
    opacity: 0.4;
}

.containermenu .h4bleiben {
    color:#FF0266;
    /* text-shadow: 2px 2px 3px rgba(255,255,255,0.3); */
    opacity: 1;
}

.containermenu .spanh4 {
    font-weight: 300;
    font-size: 20px;
    display: inline-block;
}

.menu-list-verbs {
    height: 100%;
    width: 100%;
}


.containerbuttonmenu{
    margin-top: 40px;
    width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

 div::-webkit-scrollbar {
    width: 10px;
  }
  
   /* Track  */
 div::-webkit-scrollbar-track {
    background:transparent;
    
  }
  
   /* Handle  */
  div::-webkit-scrollbar-thumb {
    background: white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.1);
    border-radius: 13px;
  } 