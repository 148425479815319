.points {
    font-style: roboto;
    font-size: 3rem;
    font-weight: bolder;
    color: black;
    background-color: #FFDE03;
    cursor: none;
    display: flex;
    justify-content: center;
    align-items: center;

}

@media (max-width: 500px){
    .points{
        font-size: 2.5rem;
    }
}

.spanpoints{
    font-size: 50%;
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: 500;
}
