#progressbar{
    background-color: white;
    width: 100%;
}

#bar {
    height: 6px;
    background-color: #FF0266;
    transition-duration: 3s;

}


