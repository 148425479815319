.youareawesome{
  height: 80vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.icon-poop {
  font-size: 5em;
  color: white;
}

.youareawesome h4{
  color: black;
  text-shadow: none;
  font-size: 3rem;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  text-align: left;
}
