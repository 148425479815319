.wrapperheartpoints{
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
}


@media (max-width: 500px){
  .wrapperheartpoints{
    font-size: 13px;
  }
}
