.verbsfilling {
  margin-left: 5%;
  margin-right: 5%;
  height: 500px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .verbsfilling {
    margin-top: 40px;
  }
}

.verbsfilling h1 {
  font-weight: bolder;
  height: 60px;
  font-size: 5rem;
  text-align: center;
  color: black;
}

@media (max-width: 600px) {
  .verbs h1 {
    height: 60px;
    font-size: 5rem;
  }
}

input {
  width: 70px;
  color: black;
  border-bottom: solid 6px #ff0266;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0;
  color: #ff0266;
  background-color: transparent;
  padding: 0;
  text-align: center;
}

/*styling button*/

.containerbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 10vh;

}


@media (max-width: 1100px) {
  .containerbutton {
    height: 18vh;
    min-height: 150px;
  }
}

@media (max-width: 576px) {
  .containerbutton {
    height: 7vh;
    min-height: 120px;

  }
}

.btn {
  text-transform: uppercase;
  padding: 18px 40px;
  background-color: #FF0266;
  color: white;
  font-weight: 600;
  border: none;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.btn:hover {
  color: #FF0266;
  background-color: white;
}

.nonactive {
  display: none;
}

.active {
  display: inline-block;
    margin-top: 22px;

}

input[type=text]:focus {
  border: 5px solid #FF0266;
  outline-color: none;
  transition: 0.5s;

}

textarea:focus, input:focus{
  outline: none;
}
