.whole-container {
  height: calc(100vh - 60px);
  margin-top: 5px;
  padding: 8px 20px;
}

@media (max-width: 600px) {
  .whole-container {
  margin-top: 10px;
  }
}

.whole-container h1 {
  font-weight: bolder;
  height: 60px;
  font-size: 5rem;
  text-align: center;
  color: black;
}

.whole-container .vowelspink{
  font-size: inherit;
  margin-left: 1px;
}

@media (max-width: 600px) {
  .whole-container h1 {
    height: 45px;
    font-size: 4rem;
  }
}

.container-verbs{
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}


@media (min-width: 1100px) {
  .container-verbs {
    height: 400px;
  }
}

.container-verbs h1{
  align-items: center;
}

@media (max-width: 500px) {
  .container-verbs h1 {
font-size: 3.5rem;
  }
}

 /* Styling from CircularProgessbar */
.wrapper_circularprogressbar{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.wrapper_circularprogressbar span{
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-right: 20px;
  color: black;
  font-weight: 500;
  margin-bottom: 3px;
}

.CircularProgressbar{
  position: relative;
  float: right;
  width: 5rem !important;
  right: 15px;
}

@media (max-width: 500px) {
  .CircularProgressbar {
    width: 4rem !important;
  }
}

