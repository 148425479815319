.container-heart {
  justify-content: flex-end;
  display: flex;
  margin-bottom: 7px;
  background-color: #FFDE03;
  display: block inline-block;
  cursor: none;
  width: 25%;
}

.container-heart .svg-inline--fa{
  filter: drop-shadow(1px 1px 1px black);
}


span {
  font-size: 2.5em;
  display: block;
  margin-left: 4%;
}

@media (max-width: 550px) {
  span {
    margin-left: 3%;
    display: block;
    font-size: 2em;
  }
}

.hiddingheart{
  color: #FFDE03;
  }

