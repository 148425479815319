body {
  background-color: #ffde03;
  font-style: roboto;
}

.navbar {
  text-align: center;
  margin: 0;
  height: 60px;
  border: none;
}

.navbar-brand {
  height: 100%;
  line-height: 140%;
  font-size: 16px;
}

.logo {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1); 
}

.logoh1 {
  margin: 0;
  font-size: 120%;
  font-weight: bolder;
}

.logoh6 {
  margin-top: 0px;
  text-transform: uppercase;
  font-weight: 300;
}

.newgame {
  align-items: center;
  font-size: 15px;
  padding-top: 19px;
  cursor: pointer;
  background-color: #FF0266;
  color: white;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1); 
}
.newgame:hover{
  background-color: black;
  color: white;
}

.restart{
  font-size: 15px;
  padding-top: 19px;
  padding-right: 20px;
  color: #FF0266;
  float: right;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1); 
}

.restart:hover{
  color: black;
}

@media (max-width: 576px){
  .newgame, .restart {
    font-size: 70%;
  }
  .navbar-brand{
    line-height: 180%;
    padding: 19px 8px 15px 8px;
  }
  .logo{
    padding-top: 15px;
  }
}

.logo a {
  text-decoration: none;
  color: inherit;
}

.newgame a {
  text-decoration: none;
  color: white;
}

.newgame a:hover {
  text-decoration: none;
  color: white;
}

.logo a:hover{
  text-decoration: none;
  color: black;
}
