body {
  background-color: #FFDE03;
  font-style: roboto;
  color: #FF0266;
  height: 500px;

}

.welcomecontainer {
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  text-align: center;
  color: #FF0266;
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.w3 {
  font-size: 3rem;
}

.w1 {
  font-size: 100px;
  margin-top: -9px;
}

.w2{
  font-size: 25px;
  margin-top: -49px;
  margin-bottom: -18px;
}

.w4{
  margin-top: 0;
  font-size: 18px;
  margin-bottom: -36px;
}

.w6 {
  font-size: 27px;
  margin-top: -42px;
  color: black;
}

.w5 {
  font-size: 27px;
  margin-top: -21px;
  color: black;
}

.containerwelcome {
  height: 90vh;
}

.containerbuttonstart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
