.notfound {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 550px;
    color: black;
    font-size: 150%;
}

.notfound span {
    font-weight: bolder;
}