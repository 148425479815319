.congrats {
    height: 630px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(#FFDE03 30%, deeppink );
}

.displaypoints{
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 14px;
    color: white;
    text-shadow: 0.3px 0.3px 0.3px white;
}

.congrats h1 {
    font-size: 100px;
}

.congrats h2 {
    text-align: center;
    font-size: 200%;
    color: white;
    font-weight: 900;
    /*text-shadow: 0.3px 0.3px 3.3px white;*/
    text-transform: uppercase;
}

.congrats a {
font-size: 75%;
    width: 250px;
}


.whatsappshare {
    justify-content: center;
    display: flex;
    flex-direction: column-reverse;
    font-size: 80%;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: white;
    margin-top: 40px;
    background-color: #25D366;
    margin-bottom: 30px;
}

.facebookshare{
    background-color: #29487D;
    margin-bottom: 30px;
    font-size: 80%;
    justify-content: center;
    display: flex;
    color: white;
}

.twittershare{
    background-color: #55ACEE;
    font-size: 80%;
    justify-content: center;
    display: flex;
    color: white;
}