body{
font-size: 150%;
}

.swal2-title{
    color: #FF0266 !important;
    font-size: 150% !important;
}


